@import "../website-v2/_variables.less";

.integrations {
	--list-border-width: 0.125rem;
	--list-label-lh: 1.3;
	--list-label-height: calc(var(--octo-font-size-normal) * var(--list-label-lh));
	--integrations-mobile-columns-gap: var(--space-16);
	--integrations-dropdown-height: 3.25rem;

	align-items: start;
	gap: var(--space-40);
	position: relative;

	@media screen and (max-width: @screen-lg) {
		grid-template-columns: 1fr;
		gap: var(--integrations-mobile-columns-gap);
	}

	&__column {
		display: flex;
		flex-direction: column;
		gap: var(--space-32);
		z-index: 1;

		// Vartical connecting lines
		@media screen and (max-width: @screen-lg) {
			position: relative;
			gap: var(--integrations-mobile-columns-gap);

			&:not(:last-of-type)::before {
				content: '';
				display: block;
				position: absolute;
				border: var(--list-border-width) solid var(--input-border);
				border-radius: 1rem;
				left: -1rem;
				height: calc(100% + var(--list-border-width) + var(--integrations-mobile-columns-gap));
				top: calc(var(--integrations-dropdown-height)/2);
				width: 2rem;
				border-right: 0;
			}

			&.active {
				.integrations__header {
					border-color: #7EC6FD;
					> svg {
						transform: rotate(180deg);
					}
				}
				.integrations__list {
					display: flex;
				}
			}
		}
	}

	&__list {
		display: flex;
		flex-direction: column;
		gap: var(--space-8);
		border-radius: 1.125rem;
		border: var(--list-border-width) solid var(--input-border);
		background: var(--integrations-column-background);
		padding: var(--space-32) var(--space-16) var(--space-16);
		position: relative;
		transition: border-color ease-in-out 200ms;

		@media screen and (max-width: @screen-lg) {
			display: none;
			border: none;
		}

		&:hover {
			border-color: var(--input-border-selected);
		}

		.integrations__label-item:first-child {
			position: absolute;
			margin: 0;
			top: calc((var(--list-label-height) + var(--list-border-width)) / -2);
			padding: 0 var(--space-12);
			margin-left: -0.2rem;

			@media screen and (max-width: @screen-lg) {
				position: static;
				padding: 0;
				margin: 0;
			}

			.stripe-gray & {
				background: var(--grey-200);
			}

			.stripe-navy & {
				background: var(--navy-500);
			}

			.stripe-dark & {
				background: var(--navy-700);
			}

			.integrations__label {
				display: block;
			}
		}
	}

	&__item {
		border-radius: 0.49125rem;
		border: 0.075rem solid var(--outline-gradient-end);
		background: var(--background-tertiary);
		box-shadow: 0px 4px 1px 0px rgba(0, 0, 0, 0.50);
		list-style: none;
		padding: var(--space-8) var(--space-16);
		transition: background ease-in-out 200ms;
		height: 3.5rem;

		&:hover {
			background: var(--outline-gradient-end);
		}

		&-name {
			color: var(--text-tertiary);
			font-size: var(--octo-font-size-larger);
			font-weight: 400;
			line-height: 1.10538rem;
		}

		.grid {
			gap: var(--space-24);
			height: 100%;
			text-decoration: none;

			&.grid--two-cols-one-third {
				grid-template-columns: 1fr 4fr;
			}

			.image-wrapper {
				margin: 0;
				height: 100%;

				img {
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}

	&__label-item {
		list-style: none;
		margin: var(--space-16) var(--space-0) var(--space-8);
	}

	&__header {
		color: var(--text-primary);
		font-size: var(--h3);
		font-weight: 600;
		line-height: 130%;

		> svg {
			display: none;
		}

		@media screen and (max-width: @screen-lg) {
			border-radius: 0.6875rem;
			border: 1px solid #1F303F;
			padding: 1rem 1.25rem;
			font-size: var(--octo-font-size-normal);
			font-weight: 400;
			line-height: 140%;
			z-index: 1;
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: center;
			transition: border-color 200ms ease-in-out;

			> svg {
				display: block;
				transition: transform 200ms ease-in-out;
			}

			.stripe-gray & {
				background: var(--grey-200);
			}

			.stripe-navy & {
				background: var(--navy-500);
			}

			.stripe-dark & {
				background: var(--navy-700);
			}
		}
	}

	&__label {
		color: var(--text-primary);
		font-size: var(--octo-font-size-normal);
		font-weight: 500;
		line-height: var(--list-label-lh);
	}

	&__animation {
		position: absolute;
		width: 100%;
		top: 29%;
		margin: 0;
		padding: var(--space-16) var(--space-0);
		overflow-x: hidden;

		@media screen and (max-width: @screen-lg) {
			display: none;
		}

		&-stripe {
			position: relative;
			height: 1.25rem;
			width: 100%;
			background: var(--background-tertiary);

			&--accent {
				position: absolute;
				height: 0.1875rem;
				top: calc(-1 * var(--space-4));
				width: 100%;
				background: var(--primary-button-text);
				box-shadow: var(--integrations-animation-shadow);
			}

			.integrations__animation-ellipse {
				width: 18px;
				height: 13px;
				background: url("data:image/svg+xml,%3Csvg width='18' height='13' viewBox='0 0 18 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Ellipse 17' filter='url(%23filter0_d_5343_27833)'%3E%3Cpath d='M18 6.25C18 7.49264 16.9926 8.5 15.75 8.5C14.5074 8.5 9 7.49264 9 6.25C9 5.00736 14.5074 4 15.75 4C16.9926 4 18 5.00736 18 6.25Z' fill='%237EC6FD'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_5343_27833' x='0.9' y='0.4' width='17.1' height='11.7' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dx='-4.5'/%3E%3CfeGaussianBlur stdDeviation='1.8'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.495833 0 0 0 0 0.776724 0 0 0 0 0.991667 0 0 0 0.6 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_5343_27833'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_5343_27833' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center / contain;
				position: absolute;
				will-change: transform;
				animation-name: move-ellipse;
				animation-timing-function: ease-in-out;
				animation-iteration-count: infinite;

				>svg {
					display: block;
				}
			}
		}
	}
}

@keyframes move-ellipse {
	0% {
		transform: translateX(var(--integrations-animation-start-translate));
	}

	100% {
		transform: translateX(var(--integrations-animation-end-translate));
	}
}